import React from 'react';
import { Link } from 'react-router-dom';

function GalleryItem(key, url) {
  return (
    <li className="gallery-item" key={key}>
      <figure className="gallery-image">
        <Link to={`/ourdoctors#hero`}>
          <img src={url} alt="Gallery" loading="lazy"/>
        </Link>
      </figure>
    </li>
  );
}

function Gallery() {
  return (
    <section className="gallery" id="gallery">
      <div className="container text-center">
        <h2 className="h2 section-title">Meet our doctors</h2>
        <ul className="gallery-list">
          {[
            GalleryItem(0, "./assets/images/doctors/doctor_1.jpg"),
            GalleryItem(1, "./assets/images/doctors/doctor_2.jpg"),
            GalleryItem(2, "./assets/images/doctors/doctor_5.jpg"),
            GalleryItem(3, "./assets/images/doctors/doctor_4.jpg"),
            GalleryItem(4, "./assets/images/doctors/doctor_3.jpg"),
          ]}
        </ul>

        {/* Centered "Learn More" Button */}
        <Link to="/ourdoctors#hero" className="btn btn-primary learn-more-btn">
          Learn More
        </Link>
      </div>
    </section>
  );
}

export default Gallery;

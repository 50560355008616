import React from "react";

function BespokeSection() {
  return (
    <section className="bespoke-section" id="bespoke">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <img src={"./assets/images/lobby.jpg"} alt="Travel Image" className="img-fluid rounded" />
          </div>
          {/* Text Column */}
          <div className="col-lg-6 col-md-12">
            <h2 className="mb-4">Travel to a better you</h2>
            <p>
              {`
                Transport yourself to Croatia where your hair, dental, and cosmetic surgery needs are met by some of the best doctors in the world. 
                Sinclair Retreats is for anyone who wants to get away from prying eyes at home and get that celebrity look. 
                International travelers benefit from significant cost savings compared to domestic prices while obtaining the highest quality medical care from top-rated providers. 
                We ensure a discreet and private experience, combined with luxury accommodations and personalized services. 
                Our staff manages all travel arrangements and medical appointments, making the process hassle-free. Every provider has been vetted and is the best in their field. 
                Let us help you achieve your health and wellness goals while enjoying a luxurious getaway and learn more about how we can tailor your trip. 
                A better you isn’t just for the wealthy anymore.
              `}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BespokeSection;
import React from 'react';
import ScrollLink from './ScrollLink';

function Hero() {
  return (
    <section className="hero" id="home">
      <div className="container">
      </div>
      <ScrollLink to={'/#bespoke'}>
        <div className="btn-group">
          <span>
            Start Your Journey
          </span>
          <img src="./assets/images/arrow_white.png" alt="white arrow pointing down"></img>
        </div>
      </ScrollLink>
    </section>
  );
}

export default Hero;

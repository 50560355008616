import React from 'react';

function Package(orderLarge, orderSmall, placement, name, subtitle, description, price, image, alt) {
  return (
    <>
      <div className={`text-center flex-grow-1 d-flex flex-column justify-content-center order-${orderLarge[0]} order-md-${orderSmall[0]}`}>
        <h3 className={`section-subtitle ${placement}`}>
          {name}
        </h3>
        <b><i>{subtitle}</i></b>
        <p>
          {description}
        </p>
        <strong><i style={{fontSize: '0.9rem'}}>{price ? `Starting from $${price}` : 'Priced upon request'}</i></strong>
      </div>
      <img
        className={`img-fluid rounded ${placement ? 'mb-5' : 'mt-5'} order-${orderLarge[1]} order-md-${orderSmall[1]}`}
        src={image}
        alt={alt}
        loading="lazy"
      />
    </>
  )
}

function PopularDestinations() {
  return (
    <section className="popular" id="packages">
      <div className="container text-center">
        <div className="row">
          <h2 className="col align-self-center section-title h2">Our Packages</h2>
        </div>
        <div className="row mt-3 justify-content-center">

          {/* Column 1 */}
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column justify-content-between align-items-center mb-4 max-column staggered">
            {
              Package(
                [1,2],
                [2,1],
                'bottom', 
                'Hair', 
                `Experience a full hair transplant with the world's best doctors`, 
                `Journey to one of the best doctors in the country to get the hair you dream of having. Spend 3-4 days in paradise at a 5-Star hotel relaxing after your procedure before returning home.`,
                '4,000',
                './assets/images/package2.jpg',
                'Palace of a Roman Emperor in loacated in Split.'
              )
            }
          </div>

          {/* Column 2 */}
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column justify-content-between align-items-center mb-4 max-column">
            {
              Package(
                [1,1],
                [2,2],
                '', 
                'Dental', 
                `Receive the care you need from top dental facilities`, 
                `Arrive for a 2-3 day retreat for anything between a set of veneers to an entire dental transplant. We picked the best two dentists in the region and they will walk you through the entire process.`,
                '8,000',
                './assets/images/package1.jpg',
                'Woman lounging on a yacht chartered for a day of pre-treatment relaxation.'
              )
            }
          </div>

          {/* Column 3 */}
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column justify-content-between align-items-center mb-4 max-column staggered">
            {
              Package(
                [1,2],
                [2,1],
                'bottom', 
                'Cosmetic', 
                `Breast augmentation to liposuction, we have you covered`, 
                `Get connected with hand-picked plastic surgeons as you start your new look. Stay at the area’s best hotels while you complete your procedure and recover in a beautiful facility.`,
                '7,500',
                './assets/images/package3.jpg',
                ''
              )
            }
          </div>

          {/* Column 4 */}
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column justify-content-between align-items-center mb-4 max-column">
            {
              Package(
                [1,1],
                [2,2],
                '', 
                'Private Travel', 
                `Extend your stay and experience all the country has to offer`, 
                `Before or after your treatment, we can help plan a bespoke add-on to your trip. Explore the islands or take some time on a walking tour in the city.`,
                '',
                './assets/images/package4.jpg',
                ''
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularDestinations;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ScrollLink({ to, ...props }) {
  const location = useLocation();

  const handleClick = (e) => {
    const [pathname, hash] = to.split('#'); // Split pathname and hash

    if (hash && location.pathname === pathname) {
      // Prevent default navigation behavior ONLY if the paths match (we are on the same page)
      e.preventDefault();

      // Scroll to the element with the corresponding ID
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
      }

      // Update the hash in the URL without reloading the page
      window.history.pushState(null, '', `#${hash}`);

      setTimeout(() => {
        e.target.blur(); // Removes the focus from the link
      }, 300); // Adjust the delay based on your scroll speed preference
    }
  };

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {props.children}
    </Link>
  );
}

export default ScrollLink;

import React from 'react';
import { Link } from 'react-router-dom';

function MissionSection() {
  return (
    <section className="mission-section" id="mission">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <h2 className="mb-4 h2">Our Mission</h2>
            <p className="mission-text">
              {`
                Sinclair Retreats strives to bring celebrity style treatments to those that can’t afford to spend tens of thousands on medical procedures. 
                We hand-select doctors that are the highest rated experts in their fields while ensuring affordable care. 
                Roundtrip airport transfers, stay at a luxury hotel, and an attendant to help navigate you through the process 
                ensures you have everything you need for a smooth and relaxing experience overseas.
              `}
            </p>
            <p className="mission-text">
              {`
                Once you place a fully-refundable deposit, we’ll schedule an appointment with your doctor for a 30-minute video consultation. 
                From there, we handle everything, ensuring an experience of style and comfort. 
                Medical treatments should be easy, relaxing, and make you feel like a better you. 
                We aim to provide that from start to finish.
              `}
            </p>
          </div>
        </div>
        {/* Centered "Learn More" Button */}
        <Link to="/ourteam#hero" className="btn btn-primary learn-more-btn">
          Meet our team
        </Link>
      </div>
    </section>
  );
}

export default MissionSection;

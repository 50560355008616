import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { hash } = useLocation(); // Get the current hash in the URL

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash); // Find the element with the corresponding id
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' }); // Scroll to the element smoothly
        }
        document.activeElement.blur();
      }
    }, [hash]); // Runs when hash changes
  
    return null;
}

export default ScrollToTop;
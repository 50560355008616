import React, { useState } from 'react';

function CTA() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    package: 'dental', // Default selection for the dropdown
    month: 'January',  // Default selection for the dropdown
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Email sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          package: 'dental',
          month: 'January',
        });
      } else {
        setStatus('Failed to send email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <section className="contact-form-section py-5" id="cta">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <h2 className="form-heading mb-4">Travel with us!</h2>
            <p className="lead">
              Please take the time to fill out the form and a member of our team will reply shortly to start the journey to a better you.
            </p>
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Enter your full name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="form-control"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="package">Package</label>
                <select
                  id="package"
                  name="package"
                  className="form-control"
                  value={formData.package}
                  onChange={handleChange}
                  required
                >
                  <option value="dental">Dental</option>
                  <option value="cosmetic">Cosmetic</option>
                  <option value="hair">Hair</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="month">Preferred Month of Travel</label>
                <select
                  id="month"
                  name="month"
                  className="form-control"
                  value={formData.month}
                  onChange={handleChange}
                  required
                >
                  {[
                    'January', 'February', 'March', 'April', 'May', 'June', 
                    'July', 'August', 'September', 'October', 'November', 'December'
                  ].map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn btn-primary learn-more-btn w-100">
                Submit
              </button>
            </form>
            {status && <p>{status}</p>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;

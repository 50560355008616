import React from "react";

function WaveBackground(fillColor, classname) {
    return (
        <div className={classname}>
        <svg
            viewBox="0 0 1440 320"
            preserveAspectRatio="none" /* Ensures the SVG scales correctly */
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            fill={fillColor} /* Change this color as needed */
            fillOpacity="1"
            d="M0,192L60,192C120,192,240,192,360,181.3C480,171,600,149,720,154.7C840,160,960,192,1080,202.7C1200,213,1320,203,1380,197.3L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
        </svg>
        </div>
    );
  }
  
  export default WaveBackground;
import React from 'react';

const doctorsData = [
  {
    name: "Dr. Bruno Cvjetičanin",
    bio: `Dr. Bruno Cvjetičanin, MD, PhD, is a specialist in general surgery and a subspecialist in plastic, reconstructive, and aesthetic surgery. After completing his medical education at the University of Zagreb, he specialized in general surgery at KB Dubrava, followed by subspecialization in plastic surgery at KBC Zagreb. With over 5,000 surgical procedures performed, Dr. Cvjetičanin is recognized for his expertise in breast reconstruction, oncology, and aesthetic medicine. His international experience includes work at prestigious hospitals in Norway, such as Helgelandssykehuset and Oslo University Hospital. In addition to surgical procedures, he offers advanced aesthetic treatments, including fillers, neurotoxins, PRP, and mesotherapy. Dr. Cvjetičanin is also a trainer for Merz Aesthetics, ensuring his patients receive the latest innovations in aesthetic medicine. His dedication to beauty, precision, and patient satisfaction has earned him the trust of clients across Europe.`,
    photo: "./assets/images/doctors/doctor_2.jpg",
    location: "Zagreb"
  },
  {
    name: "Dr. Dinko Toncic",
    bio: `Dr. Dinko Toncic, MD, is a specialist in ENT, head and neck surgery, and aesthetic surgery. He graduated from the Faculty of Medicine in Zagreb in 2000 and completed his residency at the University Clinic in Zagreb. Since 2006, Dr. Toncic has been practicing at his own clinic, where he is known for his personalized approach to aesthetic treatments, paying close attention to each patient’s unique anatomy. He has continuously advanced his skills, participating in international conferences and refining surgical and non-surgical techniques. In 2016, he co-developed a surgical method for nasal correction, which was published in leading medical journals. Dr. Toncic is committed to ethical medical practices, ensuring patient well-being and satisfaction as his top priority.`,
    photo: "./assets/images/doctors/toncic_1.jpg",
    location: "Zagreb"
  },
  {
    name: "Dr. Ruzica Jurakic Toncic",
    bio: `Dr. Ruzica Jurakic Toncic, MD, PhD, is a dermatovenereology specialist with a focus on the early detection of malignant skin tumors. She has extensive experience from her work at the University Hospital Center Zagreb and the University of Zagreb School of Medicine. Dr. Jurakic Toncic earned her PhD in 2020, specializing in biomarkers for severe adult atopic dermatitis. Her expertise includes atopic and contact dermatitis, urticaria, and drug-related skin reactions. She has trained extensively in dermoscopy, particularly in melanoma risk, and has worked with over 2,000 high-risk patients since 2007. Dr. Jurakic Toncic is also an educator, author, and member of the International Dermoscopy Society (IDS) and has contributed to textbooks, research studies, and consensus guidelines. She serves as an expert educator for skin imaging systems and is an ambassador for the World Dermoscopy Society, making significant contributions to advancing dermatological practices worldwide.`,
    photo: "./assets/images/doctors/toncic_2.jpg",
    location: "Zagreb"
  },
  {
    name: "Dr. Mislav Gjurić",
    bio: `Prof. Dr. Mislav Gjurić, MD, PhD, is a renowned otorhinolaryngology specialist with a subspecialization in head and neck plastic surgery. His expertise spans functional-aesthetic nose surgery, endoscopic laser sinus surgery, and microsurgery of the ear and auditory nerve. He is among the few surgeons globally who perform complex auditory nerve surgeries for tumors and skull base conditions. Dr. Gjurić trained with pioneers of endoscopic sinus and skull base surgery and helped develop this field in Croatia. After earning his medical degree and doctorate from the University of Zagreb, he gained further specialization in Germany and subspecialized in skull base microsurgery and allergology. He has collaborated with world-leading experts and taught at the University of Erlangen-Nuremberg and Zagreb. A highly regarded figure in his field, Dr. Gjurić regularly lectures internationally and leads courses to train junior doctors.`,
    photo: "./assets/images/doctors/doctor_3.jpg",
    location: "Zagreb"
  },
  {
    name: "Dr. Ana Maletić",
    bio: `Dr. Ana Maletić, MD, is a specialist in plastic, reconstructive, and aesthetic surgery with a focus on hair transplantation. After graduating from the University of Zagreb's Medical Faculty in 2009, she pursued extensive training in aesthetic medicine, including laser treatments, chemical peels, and hair restoration across multiple countries. Dr. Maletić is the only Croatian physician to be a member of the International Society of Hair Restoration Surgery (ISHRS), a recognition given to top hair transplant surgeons globally. As a guest lecturer at international conferences, she shares her expertise and the work of the Maletić Polyclinic. Dr. Maletić also participates in the ISHRS Pro Bono program, offering free hair transplants to individuals who have suffered hair loss due to trauma and cannot afford treatment.`,
    photo: "./assets/images/doctors/doctor_4.jpg",
    location: "Zagreb"
  },
  {
    name: "Dr. Ivonne Žgaljardić",
    bio: `Dr. Ivonne Žgaljardić is an accomplished plastic, reconstructive, and aesthetic surgeon with extensive experience in both surgical and non-surgical cosmetic procedures. After earning the prestigious “Dean’s Award” in 2007 from the Medical School at the University of Rijeka, she gained invaluable experience as part of the abdominal transplant team, participating in over 200 transplant and organ procurement procedures. During her residency, Dr. Žgaljardić assisted and performed over 500 cosmetic surgeries, specializing in breast procedures, liposuction, abdominoplasties, and facial surgeries. She has a special interest in microsurgery and is highly skilled in laser treatments and non-invasive procedures. Recognized for her excellence, she has received awards such as "The Bizz Award" in 2017 and the "Top of the Business Award" in 2024.`,
    photo: "./assets/images/doctors/doctor_5.jpg",
    location: "Opatija"
  },
  {
    name: "Dr. Ervin Tomić",
    bio: `Dr. Ervin Tomić, born in 1962 in Mrkopalj, Croatia, is a dedicated dentist who founded his private practice with a focus on exceptional patient care, prioritizing health, aesthetics, and comfort. After completing his education at the Faculty of Dental Medicine in Rijeka, he taught at the Dental Technician School, deepening his expertise while educating future professionals. In 1992, he opened his own dental practice, later expanding to a private dental polyclinic in 2016. Dr. Tomić is committed to continuous education, specializing in implantology, aesthetic dentistry, and aesthetic medicine, including botox and hyaluronic acid treatments. Outside his professional life, he enjoys politics, bocce, diving, hunting, and eco-gardening. Dr. Tomić's diverse passions, including his culinary talents, reflect his holistic approach to both dentistry and life.`,
    photo: "./assets/images/doctors/riviera_dent_1.png",
    location: "Opatija"
  },
  {
    name: "Dr. Vana Kljaković Gašpić",
    bio: `Dr. Vana Kljaković Gašpić, MD, is a specialist in dermatovenerology with a focus on trichology, particularly scalp conditions and hair transplantation. After earning her medical degree from the Faculty of Medicine in Split in 2012, she completed her dermatovenerology specialization at KBC Zagreb's Clinic for Skin and Sexual Diseases. She gained extensive experience at Novohair in Copenhagen, one of Europe's leading clinics for hair transplantation, where she continues to work. Dr. Kljaković Gašpić has successfully performed over 1,000 hair transplant procedures. Alongside her practice in Split, she remains dedicated to advancing her expertise by attending global congresses, particularly with the International Society of Hair Restoration Surgery (ISHRS).`,
    photo: "./assets/images/doctors/doctor_1.jpg",
    location: "Split"
  },
  {
    name: "Dr. Martina Karlo",
    bio: `
      Dr. Martina Karlo is a renowned expert in aesthetic and restorative dentistry, celebrated for her precision, innovative techniques, and commitment to patient care. As the co-owner and chief dentist at her clinic in Split, Croatia, Dr. Karlo has built a strong reputation for excellence over the clinic’s five years of operation. With more than a decade of professional experience, she specializes in complex smile makeovers, dental implants, and advanced prosthodontics.
      Dr. Karlo continuously enhances her expertise, recently training with globally renowned professionals such as Dr. Duval in Dubai. Under her leadership, her clinic earned a prestigious certificate of excellence in 2024, establishing it as a top implant center. Known for her patient-centered approach and ability to deliver bespoke dental solutions, Dr. Karlo attracts clients from around the world.
    `,
    photo: "./assets/images/doctors/dentelli_3.jpg",
    location: "Split"
  }
];

function DoctorsGrid() {
  // Group doctors by location
  const locations = ["Zagreb", "Opatija", "Split"];
  const doctorsByLocation = locations.map(location => ({
    location,
    doctors: doctorsData.filter(doctor => doctor.location === location)
  }));

  return (
    <>
    <div id="hero" className="doctor-hero" style={{ backgroundColor: '#f8f9fa' }}></div>
    <div style={{ height: '25px', backgroundColor: '#f8f9fa' }}></div>
    <div className="container py-5">
      {doctorsByLocation.map((group) => (
        <section key={group.location} className="mb-5">
          <h2 className="text-center mb-4 section-title h2">{group.location}</h2>
          <div className="row">
            {group.doctors.map((doctor, index) => (
              <div
                key={doctor.name}
                className={`col-md-12 d-flex align-items-center flex-${index % 2 === 0 ? 'row' : 'row-reverse'} doctor-row`}
              >
                <div className="doctor-photo-wrapper me-3">
                  <img
                    src={doctor.photo}
                    alt={doctor.name}
                    className="img-fluid rounded-circle doctor-photo"
                    loading="lazy"
                  />
                </div>
                <div className="doctor-info">
                  <h5 className="mb-1">{doctor.name}</h5>
                  <p className="text-muted">{doctor.bio}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
    </>
  );
}

export default DoctorsGrid;
import React from 'react';
import { faqs } from './FAQPage';

function Footer() {
  // Raw HTML content for Terms & Conditions
  const termsHtmlContent = `
    <p class="c6"><span class="c0"></span></p>
    <p class="c25"><span class="c12 c20">Last updated October, 15</span><span class="c12 c20 c27">th</span><span
            class="c0">&nbsp;2024.</span></p>
    <p class="c17 c26"><span class="c0"></span></p>
    <p class="c6"><span class="c0"></span></p>
    <p class="c9"><span class="c2 c8">Sinclair Retreats LLC</span></p>
    <p class="c9 c17"><span class="c8 c2"></span></p>
    <p class="c9"><span class="c8 c2">Service Terms and Conditions</span></p>
    <p class="c6"><span class="c0"></span></p>
    <p class="c29"><span class="c0">These Sinclair Retreats Service Terms and Conditions (these &ldquo;Terms&rdquo; or
            &ldquo;Agreement&rdquo;), constitute a binding agreement between Sinclair Retreats and Client (each, a
            &ldquo;Party&rdquo;) under which Sinclair Retreats provides Client access to Sinclair Retreats&rsquo;
            Services. By using Sinclair Retreats&rsquo; Services, Client accepts and agrees to be bound by these
            Terms.</span></p>
    <ol class="c1 lst-kix_list_1-0 start" start="1">
        <li class="c22 li-bullet-0"><span class="c2 c10">DEFINITIONS</span><span class="c0">.&nbsp;Unless otherwise
                defined in the Terms, capitalized terms have the following meaning:</span></li>
    </ol>
    <ol class="c1 lst-kix_list_1-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Affiliate&rdquo;&nbsp;means an entity that controls, is
                controlled by, or is under common control with a Party. For this definition, &ldquo;control&rdquo; means
                direct or indirect ownership of more than 50% of the voting interests of the subject entity.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Applicable Laws&rdquo;&nbsp;means all applicable local,
                state, federal and international laws and regulations.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Sinclair Retreats&rdquo;&nbsp;means Sinclair Retreats, LLC,
                and its Affiliates.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Sinclair Retreats Technology&rdquo;&nbsp;means the technology
                and Intellectual Property used in providing the products and services offered by Sinclair Retreats,
                including computer software programs, connectors, websites, networks, and equipment. </span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Client&rdquo;&nbsp;means the person or entity that uses the
                Services, and thereby accepts these Terms.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Client Data&rdquo;&nbsp;means any information uploaded to
                Sinclair Retreats&rsquo; systems by Clients, the resulting Client unique output that is generated by the
                Services when processing the uploaded information, and any information provided by Client in connection
                with its use of the Services.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Documentation&rdquo;&nbsp;means Sinclair Retreats&rsquo;
                Client guides and other similar information, as updated or revised by Sinclair Retreats from time to
                time, that Sinclair Retreats may provide to Client.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Intellectual Property&rdquo;&nbsp;means all trade secrets,
                patents and patent applications, trademarks (whether registered or unregistered and including any
                goodwill acquired in such trademarks), service marks, trade names, copyrights, moral rights, rights in
                Inventions, and all other intellectual property and proprietary rights (whether registered or
                unregistered, any application for the foregoing, and all rights to enforce the foregoing), and all other
                equivalent rights that may exist anywhere in the world.</span></li>
        <li class="c4 li-bullet-0"><span class="c0">&ldquo;Services&rdquo;&nbsp;mean the Sinclair Retreats service
                offerings which Client uses.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_1-0" start="2">
        <li class="c28 li-bullet-0"><span class="c2 c10">THE SERVICES</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c18 li-bullet-0"><span class="c2">Use of the Services.</span><span class="c0">&nbsp;Sinclair Retreats
                grants Client a nonexclusive, nontransferable, worldwide right to access and use the Services. </span>
        </li>
        <li class="c4 li-bullet-0"><span class="c2">Client&rsquo;s Information.&nbsp;</span><span class="c0">Sinclair
                Retreats shall enable Client to access the Services. Client shall maintain the confidentiality of
                passwords, access, and information under their control. Sinclair Retreats is not responsible for
                unauthorized access. Client shall contact Sinclair Retreats promptly if (i) information is lost, stolen,
                or disclosed to an unauthorized person; (ii) Client reasonably believes that the Services have been
                compromised, including any unauthorized access, use, or disclosure of information; or (iii) any other
                breach of security in relation to Client&rsquo;s passwords, or access information, or Sinclair
                Retreats&rsquo; Services may have occurred or is reasonably likely to occur.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Sinclair Retreats&rsquo; Responsibilities.&nbsp;</span><span
                class="c0">Sinclair Retreats shall: (i) use commercially reasonable efforts to make the Services
                available during regular business hours, except for unavailability caused by circumstances beyond
                Sinclair Retreats&rsquo; reasonable control, including internet service provider failures or delays or
                denial of service attacks against which Sinclair Retreats maintains commercially reasonable controls;
                (ii) provide its basic support for the Services to Client, and (iii) conduct its business in compliance
                with Applicable Laws.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Client&rsquo;s Responsibilities.</span><span class="c0">&nbsp;Client
                is responsible for (i) the accuracy and completeness of Client&rsquo;s initial and ongoing set up of the
                Services, (ii) ensuring that the Services are compatible with Client&rsquo;s requirements; (iii) the
                accuracy, quality, and integrity of the Client Data; and (iv) the maintenance and use of Client&rsquo;s
                hardware, network, internet connectivity, and software. Client shall comply with all Applicable Laws.
            </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Restrictions.</span><span class="c0">&nbsp;Client shall only use the
                Services as set forth in the Terms and the Documentation. Client shall not (i) reverse assemble, reverse
                engineer, decompile, or otherwise attempt to derive source code from any of the Sinclair Retreats
                Technology; (ii) reproduce, modify, create, or prepare derivative works of any of the Sinclair Retreats
                Technology or Documentation; (iii) distribute or display any of the Sinclair Retreats Technology or
                Documentation; (iv) share, sell, rent, lease, or otherwise distribute access to the Services other than
                with Sinclair Retreats&rsquo; by prior written consent; or (v) alter, destroy or otherwise remove any
                proprietary notices within the Sinclair Retreats Technology or Documentation.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0 start" start="1">
        <li class="c3 li-bullet-0"><span class="c2 c10">PROPRIETARY RIGHTS</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Sinclair Retreats&rsquo; Intellectual Property.</span><span
                class="c0">&nbsp;As between Client and Sinclair Retreats, Sinclair Retreats retains and owns all right,
                title, and interest in all Intellectual Property rights in the Sinclair Retreats Technology, the
                Documentation, Sinclair Retreats&rsquo; Confidential Information, the Services, and all enhancements or
                improvements to, or derivative works of, the foregoing. Nothing in this Agreement transfers or conveys
                to Client any ownership interest in the Sinclair Retreats Intellectual Property.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Suggestions.</span><span class="c0">&nbsp;If Client provides
                Sinclair Retreats with any suggested improvements to the Services, then that suggestion is provided as
                is, and Client grants Sinclair Retreats a nonexclusive, perpetual, irrevocable, royalty free, worldwide
                license, with rights to transfer, sublicense, sell, use, reproduce, display, and make derivative works
                of such suggested improvements.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Client Data.</span><span class="c0">&nbsp;Client retains all
                ownership rights in Client Data and Client&rsquo;s Confidential Information. Sinclair Retreats may use
                Client Data solely (i) for internal use in order to maintain, evaluate, develop, and improve its
                Services; (ii) to respond to a Client request; (iii) to fulfill its obligations to Client by sharing the
                Client Data with entities selected by Client; or (iv) to comply with Applicable Laws. Nothing in the
                Terms transfers or conveys to Sinclair Retreats any ownership interest in or to the Client Data or
                Client&rsquo;s Confidential Information.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Aggregate Data.</span><span class="c0">&nbsp;Sinclair Retreats shall
                strictly comply with Applicable Laws and the confidentiality provisions of the Terms with respect to
                Aggregate Data.&nbsp;&ldquo;Aggregate Data&rdquo;&nbsp;means de-identified and anonymized sets of data
                that have been gathered by Sinclair Retreats for the purpose of expressing that information in summary
                form. Aggregate Data does not include any information that could be used to identify, or re-identify,
                Client. Sinclair Retreats may use Aggregate Data for any purpose. </span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="2">
        <li class="c3 li-bullet-0"><span class="c2 c10">SERVICE SUSPENSION AND DISPUTES</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Generally.</span><span class="c0">&nbsp;Sinclair Retreats shall make
                a good faith effort to contact and provide notice to Client in advance of any suspension. As reasonably
                practicable under the circumstances, Sinclair Retreats shall endeavor to resolve together with Client
                any circumstance that may give rise to Sinclair Retreats&rsquo; suspension rights, which include,
                without limitation, the following: (i) a material risk to the security or performance of the Services,
                or any other Sinclair Retreats Client or business partner; (ii) use of the Services in violation of the
                Terms; or (iii) Client is delinquent in its payment obligations for any undisputed fees. Sinclair
                Retreats acknowledges that suspending Client&rsquo;s right to access or use some or part of the Services
                is a significant action, and, therefore, Sinclair Retreats shall not exercise this remedy except in good
                faith and as necessary to resolve the issue giving rise to Sinclair Retreats&rsquo; right to suspend the
                Services. Sinclair Retreats shall also pursue other less drastic measures it deems appropriate.
                &nbsp;</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Effect of Suspension.</span><span class="c0">&nbsp;If Sinclair
                Retreats suspends Client&rsquo;s ability to access the Services, (i) Client remains responsible for all
                fees and charges for suspended Services and for other Services to which Client continues to have access,
                if any; and (ii) Client will not be entitled to any compensation for any period of suspension.</span>
        </li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="3">
        <li class="c3 li-bullet-0"><span class="c2 c10">TERM AND TERMINATION</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Agreement Term.</span><span class="c0">&nbsp;The term of the
                Agreement (the&nbsp;&ldquo;Term&rdquo;) begins on the Effective Date for the first Service used, and
                ends on the date of termination.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Term.</span><span class="c0">&nbsp;Client may use one or more
                Services during the Term. The effective period is 24 months. Each Term begins on
                an&nbsp;&ldquo;Effective Date,&rdquo;&nbsp;which is when either (1) Client begins using the Sinclair
                Retreats Services or (2) an alternate date mutually agreed by the Parties in writing.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Automatic Renewal.</span><span class="c0">&nbsp;At the end of the
                then-current Term, each Service will automatically renew for an additional one-year period unless (a)
                Client provides written notice of non-renewal to Sinclair Retreats on or before the expiration date of
                the then-current Term, or (b) Sinclair Retreats provides written notice of non-renewal to Client at
                least 30 days before such expiration date. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Termination for Breach or Cause.</span><span class="c0">&nbsp;Either
                Party may terminate this Agreement or any affected Services by notice to the other if the other Party
                materially breaches its obligations under this Agreement and, if the breach is capable of cure, fails to
                cure the breach within 30 days of the date of notice of breach. Material breach by Client includes
                Client being unable to resolve any issue leading to suspension of Client&rsquo;s Services to Sinclair
                Retreats&rsquo; reasonable satisfaction within 30 days following notice of suspension.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">General Effects of Termination.</span><span class="c0">&nbsp;Upon
                any termination of the Agreement: (i) all of Client&rsquo;s rights under the Agreement immediately
                terminate; (ii) Client remains liable for all fees, charges, and any other obligations Client has
                incurred during the Term; (iii) each Party shall at its own election immediately return or destroy the
                other Party&rsquo;s Confidential Information in its possession or control other than Confidential
                Information that must be retained for regulatory, legal, or audit purposes or for compliance with
                applicable laws; and (iv) Sinclair Retreats shall destroy or overwrite Client Data within a reasonable
                period of time. All provisions that by their nature should survive termination will do so (including, by
                way of example and not limitation, payment obligations, indemnification, and duties of
                confidentiality).</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="4">
        <li class="c3 li-bullet-0"><span class="c2 c10">CONFIDENTIAL INFORMATION</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Confidential Information.&nbsp;</span><span
                class="c0">&ldquo;Confidential Information&rdquo;&nbsp;means all information designated by a Party as
                confidential, or given the circumstances, would reasonably be understood by the receiving Party to be
                confidential, and that is disclosed by either Party to the other Party, regardless of the form of
                disclosure. Confidential&nbsp;Information&nbsp;includes, with respect to Sinclair Retreats, the Sinclair
                Retreats Technology and the Documentation, and with respect to Client, all Client Data, and with respect
                to both Parties.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Exclusions.</span><span class="c0">&nbsp;Confidential Information
                does not include information that the receiving Party can establish: (i) becomes generally known to the
                public without the receiving Party&rsquo;s breach of any obligation owed to the disclosing Party; (ii)
                has been rightfully received by the receiving Party from a third Party without confidentiality
                restrictions; (iii) is known to the receiving Party without any restriction as to use or disclosure
                prior to first receipt by the receiving Party from the disclosing Party; or (iv) has been independently
                developed by the receiving Party without use of or reference to the disclosing Party&rsquo;s
                Confidential Information.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Disclosures Required by Law.</span><span class="c0">&nbsp;If any
                Applicable Laws or judicial or administrative order requires the receiving Party to disclose any of the
                disclosing Party&rsquo;s Confidential Information (a&nbsp;&ldquo;Disclosure Order&rdquo;) then, unless
                otherwise prohibited by the Disclosure Order, the receiving Party shall promptly notify the disclosing
                Party in writing prior to making any such disclosure, in order to facilitate the disclosing
                Party&rsquo;s efforts to protect its Confidential Information. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Protection of Client Data and Confidential Information.</span><span
                class="c0">&nbsp;Sinclair Retreats shall implement and maintain commercially reasonable and appropriate
                technical, administrative, and physical safeguards and security methods designed to prevent any
                unauthorized release, access to or publication of Client Data and Confidential Information. </span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="5">
        <li class="c3 li-bullet-0"><span class="c2 c10">WARRANTIES</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Mutual Warranties.</span><span class="c0">&nbsp;Each Party
                represents and warrants to the other Party that (i) it has the authority to enter into this Agreement
                and perform its obligations hereunder; and (ii) the Agreement does not conflict with any other agreement
                entered into by it. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Sinclair Retreats&rsquo; Warranties.</span><span
                class="c0">&nbsp;Sinclair Retreats warrants to Client that: (1) the Sinclair Retreats Technology
                Sinclair Retreats provides to Client will perform in all material respects in accordance with its
                applicable specifications and any Documentation; and (2) Sinclair Retreats shall use commercially
                reasonable efforts to ensure that the Sinclair Retreats Technology provided to Client contains no
                computer virus, Trojan horse, worm, or other similar malicious code. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Client&rsquo;s Warranties.</span><span class="c0">&nbsp;Client
                represents and warrants that the information Client provides in connection with the Services, including
                billing information, is current, accurate, and complete.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Disclaimer&nbsp;of Implied Warranties</span><span class="c0">.
                Except as expressly provided in the Terms, the Services are provided on an &ldquo;as-is&rdquo; and
                &ldquo;as available&rdquo; basis, and neither Party makes any warranties of any kind, whether express,
                implied, statutory, or otherwise, and each Party specifically disclaims all implied warranties, to the
                maximum extent permitted by Applicable Laws.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="6">
        <li class="c3 li-bullet-0"><span class="c2 c10">INDEMNIFICATION</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Indemnification by Sinclair Retreats.</span><span class="c0">&nbsp;
                Sinclair Retreats shall indemnify and defend Client against losses:</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-2 start" start="1">
        <li class="c23 li-bullet-1"><span class="c0">If the Services are subject to a claim of Infringement then
                Sinclair Retreats shall, at no cost to Client, shall replace them with non-infringing or modified
                Services of materially equivalent functionality.</span></li>
        <li class="c23 li-bullet-2"><span class="c0">If the above option is not available on terms that are commercially
                reasonable for Sinclair Retreats, then Sinclair Retreats may terminate Client&rsquo;s right to access
                and use the Services, in which case Sinclair Retreats shall refund Client the amount of any prepaid,
                unused Service fees minus any credits card fees, bank fees, or other transaction costs. </span></li>
        <li class="c23 li-bullet-2"><span class="c0">Sinclair Retreats has no obligation with respect to any actual or
                claimed Infringement to the extent that the Infringement is caused by (1) Client Data, or (2) use or
                modification of the Sinclair Retreats Services other than as specified in the Documentation or these
                Terms,.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1" start="2">
        <li class="c4 li-bullet-0"><span class="c2">Indemnification by Client.</span><span class="c0">&nbsp;Client shall
                indemnify and defend Sinclair Retreats against losses that result from Client&rsquo;s breach of the
                Terms, or &nbsp;results from Client&rsquo;s violation of Applicable Laws.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Exclusive Remedy.</span><span class="c0">&nbsp;This Section
                (Indemnification) states the Indemnitor&rsquo;s sole liability and the Indemnitee&rsquo;s exclusive
                remedy with respect to any type of claim.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="7">
        <li class="c3 li-bullet-0"><span class="c2 c10">MODIFICATIONS</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c0">Subject to the restrictions in this Section (Modifications),
                Sinclair Retreats may modify these Terms. If Sinclair Retreats modifies these Terms. If the
                modifications materially and adversely affect Client, and Client does not wish to accept such
                modifications, then Client may terminate Client&rsquo;s use of Service by written notice to Sinclair
                Retreats within the 30-day period following the date of the Modification Notice, subject to the terms of
                this Section (Modifications).</span></li>
        <li class="c4 li-bullet-0"><span class="c0">If Client does not terminate the affected Service as specified in
                this Section (Modifications), then Client will be bound by the modified terms.</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="8">
        <li class="c3 li-bullet-0"><span class="c2 c10">EXCLUSION OF CERTAIN CLAIMS; LIMITATION OF LIABILITY</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Exclusion of Certain Claims. </span><span class="c0">Neither Party
                will be liable to the other Party or any other party for any consequential, indirect, special, punitive,
                incidental, exemplary or lost profits damages of any kind, whether foreseeable or unforeseeable,
                including damages for loss of data, goodwill, investments, use of money, arising out of (i) the
                performance or nonperformance of the Agreement or of products, software, Services, or Sinclair Retreats
                Services provided under the Terms, or (ii) any claim, cause of action, breach of contract, indemnity, or
                any express or implied warranty, misrepresentation, negligence, strict liability, or other tort.</span>
        </li>
        <li class="c4 li-bullet-0"><span class="c2">Limitation of Liability.</span><span class="c0">&nbsp;A
                Party&rsquo;s aggregate liability will not exceed the fees paid or payable by Client to Sinclair
                Retreats under the Terms in the 3-month period immediately preceding the event giving rise to the claim.
                The previous sentence does not apply to Client&rsquo;s obligations to pay fees and Expenses when due and
                payable, nor to any infringement or misappropriation by a Party of any Intellectual Property rights of
                the other Party.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Limitation of Claims.</span><span class="c0">&nbsp;Except with
                respect to claims of infringement or misappropriation of any Intellectual Property, misuse of
                Confidential Information or Corporate Information, or Client&rsquo;s failure to pay amounts due, neither
                Party may bring any claim relating to the Terms more than one year after the events giving rise to the
                claim occurred.</span></li>
        <li class="c4 li-bullet-0"><span class="c19">Independent Providers.</span><span class="c12">&nbsp;Sinclair
                Retreats, its employees, shareholders, officers, directors, successors, agents and assigns, do not own
                or operate any property or entity which provide medical services. Sinclair Retreats organizes
                transportation, hotel and other lodging accommodations, restaurants, ground handling and other services
                from various independent suppliers. All such persons and entities are independent contractors. Sinclair
                Retreats is not liable for any negligent or willful act of any such person or entity, or of any third
                party. In addition, and without limitation, Sinclair Retreats is not responsible for any injury,
                financial or physical loss, death, inconvenience, delay or damage to personal property in connection
                with the provision of services whether resulting from but not limited to acts of God or force majeure,
                overbooking or downgrading of services, or failure of transportation. There are many inherent risks
                inherent in travel and medical procedures which can lead to illness, injury, or even death. Clients
                assume all such risks associated with participating in these trips. Client release Sinclair Retreats
                from all liabilities known or unknown and agrees not to sue or make claim against Sinclair Retreats for
                property damage, cancellation of any services for any reason, illness, procedures, personal injury, or
                death arising out of your participation in the Services, and any activity related thereto, regardless of
                whether from negligence of Sinclair Retreats or from any defect in the equipment. Client further agrees
                to indemnify and hold Sinclair Retreats harmless with respect to any claim made against Sinclair
                Retreats by anyone else which related to Client use of Services. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">General.</span><span class="c0">&nbsp;These exclusions and
                limitations apply even if the remedies are insufficient to cover all of the losses or damages of Client.
                Without these limitations the fee for the Services and Sinclair Retreats Services would be significantly
                higher. </span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-0" start="9">
        <li class="c3 li-bullet-0"><span class="c2 c10 c16">PRIVACY POLICY</span></li>
    </ol>
    <p class="c24"><span class="c0">Please refer to our Privacy Policy, which is incorporated into the Terms and
            Conditions, for information on how we collect, use and share data and Personal Information.</span></p>
    <ol class="c1 lst-kix_list_2-0" start="10">
        <li class="c13 li-bullet-0"><span class="c2 c10 c16">HIPAA</span></li>
    </ol>
    <p class="c5"><span class="c0">Client understands that under the Health Insurance Portability &amp; Accountability
            Act of 1996 (&ldquo;HIPAA&rdquo;), Client has certain rights to privacy regarding protected health
            information (PHI). Client understands that the information may be used for the reasons and conditions below:
        </span></p>
    <ul class="c1 lst-kix_list_4-0 start">
        <li class="c11 li-bullet-0"><span class="c0">Facilitate treatment and follow-up among the multiple healthcare
                providers who may be involved in treatment directly and indirectly. </span></li>
        <li class="c15 li-bullet-0"><span class="c0">Obtain payment from third-party payers. </span></li>
    </ul>
    <p class="c5"><span class="c0">Client has received, read and understand Sinclair Retreats&rsquo; Privacy Policy
            &#8203;containing a more complete description of the uses and disclosures of health information. Client
            understands that Sinclair Retreats has the right to change its&#8203; Privacy Policy &#8203;from time to
            time and that Client may contact Sinclair Retreats at any time to obtain a current copy of the Privacy
            Policy. </span></p>
    <p class="c5"><span class="c0">Client understands that it may request in writing that Sinclair Retreats restrict how
            its private information is used or disclosed to carry out treatment, payment, or health care operations.
            Client also understands Sinclair Retreats is not required to agree to restrictions, but if Sinclair Retreats
            does agree then it is bound to abide by such restrictions. </span></p>
    <ol class="c1 lst-kix_list_2-0" start="11">
        <li class="c13 li-bullet-0"><span class="c2 c10 c16">HIPAA &ndash; Telehealth Consent </span></li>
    </ol>
    <p class="c5"><span class="c0">A Client who use any form of electronic communication with Sinclair Retreats or its
            services, including email, text, Zoom and WhatsApp sessions, should be aware that HIPAA laws are related to
            privacy rights of personal health information (PHI). This information is protected when transmitted to
            insurance companies, doctors, and others to whom Client gives consent. HIPAA laws are extensive and cover
            issues as varied as how patient and clinician can communicate among themselves and with others, and the
            nature of the information communicated. </span></p>
    <p class="c5"><span class="c0">It is necessary to inform Client that in any use of electronic forms, there always is
            the potential of hacking and/or other access to personal information by third parties. Client is encouraged
            to read more about this topic as well, which can be accessed easily on the Web and to ask further questions
            if there are any concerns.</span></p>
    <p class="c5"><span class="c0">Client understands that communications via the Internet, email systems, and phones
            may not be secure and there is no assurance of confidentiality when communicating via unencrypted platforms.
            Client understands and agrees to the following:</span></p>
    <ul class="c1 lst-kix_list_4-0">
        <li class="c11 li-bullet-0"><span class="c0">The email address provided is accurate and Client accepts
                responsibility for messages sent to or from this email address.</span></li>
        <li class="c14 li-bullet-0"><span class="c0">Email communications may be forwarded to other providers and
                documented in Client medical record for my treatment.</span></li>
        <li class="c15 li-bullet-0"><span class="c0">Client has the right at any time to revoke the authorization by
                contracting Sinclair Retreats and informing them that Client may wish to revoke its
                authorization.</span></li>
    </ul>
    <p class="c5"><span class="c0">It is important to note that most phone conversations are unencrypted as well but can
            be done through more encrypted platforms, and hence more protected, e.g., via Zoom and WhatsApp. Client is
            also aware that it may revoke this waiver at any time in writing. Client does hereby waive any concerns
            about encryption regarding phone correspondence as of date of services.</span></p>
    <ol class="c1 lst-kix_list_2-0" start="12">
        <li class="c13 li-bullet-0"><span class="c2 c10 c16">MEDICAL INFORMATION</span></li>
    </ol>
    <p class="c30"><span class="c0">In connection with the services that Client is receiving from Sinclair Retreats,
            Client hereby authorizes Sinclair Retreats to disclose any/or all information concerning medical conditions
            and treatments, including copies of applicable hospital and medical records, to :</span></p>
    <ol class="c1 lst-kix_list_2-1" start="6">
        <li class="c18 li-bullet-0"><span class="c0">Any third party payer covering my medical services;</span></li>
        <li class="c4 li-bullet-0"><span class="c0">Other health care professionals and institutions involved in the
                delivery of health care to Client;</span></li>
        <li class="c4 li-bullet-0"><span class="c0">Employees and agents, to the degree necessary to facilitate the
                provision of services and payment for such services;</span></li>
        <li class="c4 li-bullet-0"><span class="c0">Pharmacies;</span></li>
        <li class="c4 li-bullet-0"><span class="c0">Other parties as otherwise required by law;</span></li>
    </ol>
    <p class="c30"><span class="c0">In each case, Sinclair Retreats shall take reasonable steps to ensure that only the
            minimum necessary information is disclosed in accordance with the above. Client further confirms that Client
            has read the Privacy Policy and has the opportunity to place special restrictions upon the consent hereby
            given. </span></p>
    <ol class="c1 lst-kix_list_2-0" start="13">
        <li class="c13 li-bullet-0"><span class="c2 c10">MISCELLANEOUS</span></li>
    </ol>
    <ol class="c1 lst-kix_list_2-1 start" start="1">
        <li class="c4 li-bullet-0"><span class="c2">Relationship of the Parties.</span><span class="c0">&nbsp;This
                Agreement does not create a partnership, joint venture, agency, or fiduciary relationship between any of
                the Parties. Sinclair Retreats&rsquo; business partners are independent of Sinclair Retreats and are not
                Sinclair Retreats&rsquo; agents. Client acknowledges and agrees that Sinclair Retreats does not bear any
                responsibility for its business partners. </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Other Technology or Services; Changes.</span><span
                class="c0">&nbsp;Client acknowledges and agrees that Client has not relied on any availability of any
                service offerings, technology, or functionality.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Governing Law; Jurisdiction and Venue.</span><span
                class="c0">&nbsp;The Terms will be governed by laws of the state of New York, without regard to any
                laws, treaties, or conflicts of laws principles that would apply the law of any other jurisdiction.
            </span></li>
        <li class="c4 li-bullet-0"><span class="c2">Equitable Relief.</span><span class="c0">&nbsp;Client acknowledges
                that damages may be an inadequate remedy if Client violates the obligations under the Terms, and
                Sinclair Retreats shall have the right, in addition to any other rights it may have, to seek injunctive
                relief without any obligation to post any bond or similar security.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Force Majeure.</span><span class="c0">&nbsp;Neither Party shall be
                responsible for failure or delay of performance caused by circumstances beyond its reasonable control,
                including earthquake, storm, or other act of God; labor disputes; electrical, telecommunications, or
                other utility failures; embargoes; riots; acts of government; or acts of terrorism or war. A Party
                seeking relief from performance under this section must (i) provide notice of such circumstances to the
                other Party as soon as practicable, (ii) use all commercially reasonable efforts to avoid or mitigate
                such circumstances, and (iii) resume performance as soon as practicable upon the cessation of the
                circumstances. If the failure or delay continues for more than 30 days, the other Party may, in its
                discretion, terminate this Agreement. Such termination will not result in any liability by either Party,
                except that, if Client terminates this Agreement for Sinclair Retreats&rsquo; failure, Sinclair Retreats
                shall provide a refund for any prepaid Services that have been unused as of the date of
                termination.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Notices.</span><span class="c0">&nbsp;Sinclair Retreats shall
                communicate announcements of general interest by email or by posting on its website. Except as otherwise
                specified, legal notices are to be sent to patrick@SinclairRetreats.com. All notices must be in
                writing.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Successors and Assigns.</span><span class="c0">&nbsp;Sinclair
                Retreats may assign these Terms without Client&rsquo;s consent to an entity that acquires all or
                substantially all of its assets pertaining to this Agreement.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Severability.</span><span class="c0">&nbsp;If any provision of the
                Terms is determined to be invalid or unenforceable by any court, then to the fullest extent permitted by
                law, that provision will be deemed modified to the extent necessary to make it enforceable and
                consistent with the original intent of the Parties and all other provisions of the Terms will remain in
                full force and effect.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Waiver.</span><span class="c0">&nbsp;No waiver of any provision of
                the Terms, nor consent by a Party to the breach of or departure from any provision of the Terms, will in
                any event be binding on or effective against such Party unless it is in writing and signed by such
                Party, and then the waiver will be effective only in the specific instance and for the purpose for which
                given.</span></li>
        <li class="c4 li-bullet-0"><span class="c2">Entire Terms.</span><span class="c0">&nbsp;These Terms constitutes
                the entire agreement and understanding between the Parties with respect to the subject matter hereof and
                supersedes all prior or contemporaneous written, electronic, or oral communications, representations,
                agreements, or understandings between the Parties with respect thereto. Except as specified in Section
                on Modifications, the Terms may not be modified or amended except by a written instrument executed by
                both Parties. </span></li>
    </ol>
    <p class="c6"><span class="c0"></span></p>
  `;

  const privacyHtmlContent = `
    <p class="c4"><span class="c11">Last updated October, 25</span><span class="c11 c13">th</span><span
            class="c0">&nbsp;2024.</span></p>
    <p class="c2"><span class="c1 c8">Sinclair Retreats</span></p>
    <p class="c2"><span class="c1">Privacy Policy</span></p>
    <p class="c5"><span class="c0">This Privacy Policy outlines Sinclair Retreats LLC (hereafter &ldquo;Sinclair
            Retreats&rdquo;) commitment to protecting the privacy of your personal information. Sinclair Retreats
            understand the importance of safeguarding your data and are dedicated to handling it responsibly and in
            compliance with applicable laws and regulations.</span></p>
    <p class="c5"><span class="c8 c1">Information Sinclair Retreats Collect</span></p>
    <p class="c5"><span class="c0">Sinclair Retreats may collect the following personal information from you:</span></p>
    <ul class="c12 lst-kix_list_1-0 start">
        <li class="c10 li-bullet-0"><span class="c1">Client Information:</span><span class="c0">&nbsp;Name, contact
                details, requirements, and any other information necessary to provide our services.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Data:</span><span class="c0">&nbsp;Documents, files, and other
                materials submitted to other service providers.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Payment Information:</span><span class="c0">&nbsp;Credit card or
                other payment details used to process transactions.</span></li>
        <li class="c7 li-bullet-0"><span class="c1">Communication Records:</span><span class="c0">&nbsp;Correspondence,
                emails, and other communications related to our services.</span></li>
    </ul>
    <p class="c5"><span class="c8 c1">How Sinclair Retreats Use Your Information</span></p>
    <p class="c5"><span class="c0">Sinclair Retreats use your personal information to:</span></p>
    <ul class="c12 lst-kix_list_2-0 start">
        <li class="c10 li-bullet-0"><span class="c0">Provide services to you.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Communicate with you regarding your services.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Process payments and handle billing.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Improve our services and customer experience.</span></li>
        <li class="c7 li-bullet-0"><span class="c0">Comply with legal and regulatory requirements.</span></li>
    </ul>
    <p class="c5"><span class="c1">Sharing Your Information</span></p>
    <p class="c5"><span class="c0">Sinclair Retreats may share your personal information with:</span></p>
    <ul class="c12 lst-kix_list_3-0 start">
        <li class="c10 li-bullet-0"><span class="c1">Medical Professionals:</span><span class="c0">&nbsp;We need to
                engage with outside service providers to assist with your services. </span></li>
        <li class="c6 li-bullet-0"><span class="c1">Subcontractors:</span><span class="c0">&nbsp;If we need to engage
                translators or service providers to assist with your services.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Payment Processors:</span><span class="c0">&nbsp;To process payments
                for our services.</span></li>
        <li class="c7 li-bullet-0"><span class="c1">Legal Authorities:</span><span class="c0">&nbsp;If required by law
                or to protect our rights or the rights of others.</span></li>
    </ul>
    <p class="c5"><span class="c1">Data Security</span></p>
    <p class="c5"><span class="c0">Sinclair Retreats implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access, disclosure, alteration, or destruction. These
            measures include: &nbsp; </span></p>
    <ul class="c12 lst-kix_list_4-0 start">
        <li class="c10 li-bullet-0"><span class="c1">Access Controls:</span><span class="c0">&nbsp;Restricting access of
                personal information to authorized personnel.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Data Encryption:</span><span class="c0">&nbsp;Encrypting sensitive
                data both at rest and in transit.</span></li>
        <li class="c7 li-bullet-0"><span class="c1">Regular Security Reviews:</span><span class="c0">&nbsp;Conducting
                periodic reviews of our security practices.</span></li>
    </ul>
    <p class="c5"><span class="c1">Your Rights</span></p>
    <p class="c5"><span class="c0">You have the right to:</span></p>
    <ul class="c12 lst-kix_list_5-0 start">
        <li class="c10 li-bullet-0"><span class="c0">Access and rectify your personal information.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Request the erasure of your personal information.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Object to the processing of your personal information. &nbsp;
            </span></li>
        <li class="c6 li-bullet-0"><span class="c0">Restrict the processing of your personal information.</span></li>
        <li class="c7 li-bullet-0"><span class="c0">Request a copy of your personal information in a structured,
                commonly used format. &nbsp; </span></li>
    </ul>
    <p class="c5"><span class="c1">Contact Us</span></p>
    <p class="c5"><span class="c0">If you have any questions or concerns about our Privacy Policy, please contact us
            at:</span></p>
    <p class="c5"><span class="c0">patrick@sinclairretreats.com</span></p>
    <p class="c5"><span class="c1">Changes to This Policy</span></p>
    <p class="c5"><span class="c0">Sinclair Retreats may update this Privacy Policy from time to time. Any changes may
            be posted on our website.</span></p>
    <p class="c14"><span class="c3"></span></p>
  `;

  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="container align-items-center">
          <p className="copyright">
            Copyright &copy; 2024 Sinclair Retreats LLC. All rights reserved.
          </p>
          <div>
            <ul className="footer-bottom-list">
              <li>
                <a 
                  href="#" 
                  className="footer-bottom-link"
                  data-bs-toggle="modal"
                  data-bs-target="#privacyModal"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="footer-bottom-link"
                  data-bs-toggle="modal"
                  data-bs-target="#termsModal"
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="footer-bottom-link"
                  data-bs-toggle="modal"
                  data-bs-target="#faqModal"
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* FAQ Modal */}
      <div className="modal fade" id="faqModal" tabIndex="-1" aria-labelledby="faqModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="faqModalLabel">Frequently Asked Questions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ul className="list-unstyled">
                {
                  faqs.map((faq, index) => (
                    <li key={index}>
                      <strong>{faq.question}</strong>
                      <p>{faq.answer}</p>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      {/* Terms & Conditions Modal */}
      <div className="modal fade" id="termsModal" tabIndex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="termsModalLabel">Terms and Conditions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body terms-and-conditions">
              <div dangerouslySetInnerHTML={{ __html: termsHtmlContent }} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Policy Modal */}
      <div className="modal fade" id="privacyModal" tabIndex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="privacyModalLabel">Privacy Policy</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body privacy-policy">
              <div dangerouslySetInnerHTML={{ __html: privacyHtmlContent }} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
